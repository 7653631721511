export default {
  title: 'Bienvenue sur le configurateur FDES Meiser',
  intro:
    "Ce configurateur vous permet de générer des FDES personnalisés en 3 étapes.<br />La création d'un compte est nécessaire si vous souahitez avoir accès à toutes les fonctionnalités.",
  steps: [
    {
      title: 'Etape 1',
      body: 'Choisir la famille de produits',
      icon: 'task_alt',
    },
    {
      title: 'Etape 2',
      body: 'Remplir les paramètres',
      icon: 'edit_document',
    },
    {
      title: 'Etape 3',
      body: 'Télécharger les résultats',
      icon: 'file_save',
    },
  ],
  button: {
    text: 'Configurer une FDES',
    loggedInRoute: 'Configurator_Step1',
    loggedOutRoute: 'Login',
  },
  images: [
    {
      img: 'caillebotis-electroforges.png',
      alt: 'Caillebotis électroforgés',
    },
    {
      img: 'caillebotis-presses.png',
      alt: 'Caillebotis pressés',
    },
    {
      img: 'sol-industriel-de-securite.png',
      alt: 'Sol industriel de sécurité',
    },
  ],
};
